.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.Card .title {
  font-size: calc(24px + 2vmin);  
}

.Card .type {
  font-size: calc(12px);  
}
