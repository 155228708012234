#root {
  height: 100%;
}

html, body {
  position: relative;
  height: 100%;
}

body {
  background-color: #E2E7ED;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}